import { fetch, post, del } from 'api/base/axios'

const URL = {
  invoiceListUrl: '/invoice/get_invoice_list/',
  createInvoiceUrl: '/invoice/create_invoice/',
  delInvoiceUrl: '/invoice/del_invoice/',
  cancelInvoiceUrl: '/invoice/cancel_invoice/',
  changeInvoiceUrl: '/invoice/change_invoice/',
  orderListUrl: '/invoice/order_list/',
  getInvoiceUrl: '/invoice/get_invoice/',
  checkInvoiceUrl: '/invoice/check_invoice/',
  checkOrderUrl: '/invoice/check_order/',
}


export function getInvoiceList (data) {
  return fetch(URL.invoiceListUrl, data)
}

export function changeInvoice (invoice_id, form) {
  return post(URL.changeInvoiceUrl + invoice_id , form)
}

export function delInvoice (form) {
  return fetch(URL.delInvoiceUrl, form)
}

export function cancelInvoice (form) {
  return fetch(URL.cancelInvoiceUrl, form)
}

export function createInvoice (form) {
  return post(URL.createInvoiceUrl, form)
}
export function getOrderList (form) {
  return post(URL.orderListUrl, form)
}

export function checkInvoice (form) {
  return post(URL.checkInvoiceUrl, form)
}

export function checkOrder (form) {
  return post(URL.checkOrderUrl, form)
}

export function getInvoice (uuid) {
  return fetch(URL.getInvoiceUrl + uuid)
}

