<template>
  <div>
    <div class="well_frame">
      <h4 class="frame_title">
        我的开票
        <div class="list_operate_btns">
          <router-link class="el-button el-button--primary el-button--small" to="/center/ticket/apply">申请开票</router-link>
        </div>
      </h4>
      <!-- 列表 -->
      <el-table :data="ticketList.data" :height="ticketList.config.height" class="table_list" v-loading="ticketList.loading" @selection-change="handleSelectionChange">
        <el-table-column type="selection"  width="55"></el-table-column>
        <el-table-column prop="type" label="发票类型">
          <template #default="scope">
            <span v-if="scope.row.type == 0">普通发票</span>
            <span v-if="scope.row.type == 1">专用发票</span>
          </template>
        </el-table-column>
        <el-table-column prop="medium" label="发票介质">
          <template #default="scope">
            <span v-if="scope.row.medium == 0">电子发票</span>
            <span v-if="scope.row.medium == 1">纸质发票</span>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="发票金额">
          <template #default="scope">
            <span>¥&#160;{{scope.row.money}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="发票抬头" width="120"></el-table-column>
        <el-table-column prop="created_at" label="申请时间" width="100"></el-table-column>
        <el-table-column prop="invoice_num" label="订单数量"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template #default="scope">
            <span v-if="scope.row.status == 0">待查看</span>
            <span v-if="scope.row.status == 1">已取消</span>
            <span v-if="scope.row.status == 2">开票中</span>
            <span v-if="scope.row.status == 3">已寄送</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template #default="scope">
            <el-button
              @click.native.prevent="showInvoice(scope.row)"
              type="text"
              size="small">
              查看
            </el-button>
            <el-button v-if="scope.row.status == 0"
              @click.native.prevent="updateInvoice(scope.row)"
              type="text"
              size="small">
              修改
            </el-button>
            <el-button v-if="scope.row.status == 0"
              @click.native.prevent="changesInvoice(scope.row)"
              type="text"
              size="small">
              取消
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination :pager="ticketList.pager" @getPager="getinvoicePager">
        <el-dropdown class="pagination_dropdown" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            批量操作<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="change" v-bind:disabled="disabledDel">取消</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </Pagination>
    </div>

    <!-- 查看申请 -->
    <el-dialog
        title="开票申请"
        v-model="detailModal.visible"
        width="1200px"
        @close="close_dialog">
        <h4 class="modal_title">申请信息</h4>
        <el-description>
          <el-description-item label="发票类型：" :span="12" labelWidth="120">
            <template #content>
                <span v-if="detailModal.form.type == 0 ">普通发票</span>
                <span v-if="detailModal.form.type == 1 ">专用发票</span>
            </template>
          </el-description-item>
          <el-description-item label="发票介质："  :span="12" labelWidth="120">
            <template #content>
                <span v-if="detailModal.form.medium == 0">电子发票</span>
                <span v-if="detailModal.form.medium == 1">纸质发票</span>
            </template>
          </el-description-item>
          <el-description-item label="发票内容：" value="培训费" :span="12" labelWidth="120" />
          <el-description-item label="发票金额：" :value="'¥'+'&#160;'+detailModal.form.money" :span="12" labelWidth="120" />
          <el-description-item label="发票抬头：" :value="detailModal.form.title" :span="12" labelWidth="120" />
          <el-description-item label="公司名称：" :value="detailModal.form.company" :span="12" labelWidth="120" />
          <el-description-item label="纳税人识别号：" :value="detailModal.form.taxpayer_num" :span="12" labelWidth="120" />
          <el-description-item label="纳税人地址：" :value="detailModal.form.taxpayer_addr" :span="12" labelWidth="120" />
          <el-description-item label="纳税人电话：" :value="detailModal.form.taxpayer_phone" :span="12" labelWidth="120" />
          <el-description-item label="开户行：" :value="detailModal.form.account_bank" :span="12" labelWidth="120" />
          <el-description-item label="开户行账号：" :value="detailModal.form.account_num" :span="12" labelWidth="120" />
          <el-description-item label="接收邮箱：" :value="detailModal.form.email_addr" :span="12" labelWidth="120"
                               v-if="(detailModal.form.medium == 0)"/>

          <el-description-item label="接收寄送地址：" :value="detailModal.form.post_addr_str" :span="12" labelWidth="120"
                               v-if="detailModal.form.medium == 1"/>

          <el-description-item label="收件人：" :value="detailModal.form.addressee" :span="12" labelWidth="120"
                               v-if="detailModal.form.medium == 1"/>

          <el-description-item label="收件人电话：" :value="detailModal.form.reci_phone" :span="12" labelWidth="120"
                               v-if="detailModal.form.medium == 1"/>

          <el-description-item label="申请时间：" :value="detailModal.form.created_at" :span="12" labelWidth="120" />
          <el-description-item label="申请状态：" :value="detailModal.form.status" :span="12" labelWidth="120">
            <template #content>
                <span v-if="detailModal.form.status == 0">待查看</span>
                <span v-if="detailModal.form.status == 1">已取消</span>
                <span v-if="detailModal.form.status == 2">开票中</span>
                <span v-if="detailModal.form.status == 3">已寄送</span>
            </template>
          </el-description-item>
          <el-description-item label="快递公司：" v-if="detailModal.form.medium == 1" :value="detailModal.form.express_company" :span="12" labelWidth="120" />
          <el-description-item label="快递单号：" v-if="detailModal.form.medium == 1"  :value="detailModal.form.express_num" :span="12" labelWidth="120" />
          <el-description-item label="寄送操作时间：" :value="detailModal.form.updated_at" :span="12" labelWidth="120" />
          <el-description-item label="备注：" :value="detailModal.form.status_instructe" :span="24" labelWidth="120" />
        </el-description>
        <h4 class="modal_title">订单信息</h4>
        <!-- 列表 -->
        <el-table :data="orderList.data" max-height="300">
          <el-table-column prop="type" label="订单类型">
            <template #default="scope">

                <span v-if="scope.row.type == 1">课程订单</span>
                <span v-if="scope.row.type == 2">会员卡订单</span>
            </template>
          </el-table-column>
          <el-table-column prop="order_sn" label="订单编号" width="200"></el-table-column>
          <el-table-column prop="goods_count" label="商品数量"></el-table-column>
          <el-table-column prop="total_amount" label="商品金额">
            <template #default="scope">
              <span>¥&#160;{{scope.row.total_amount}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="discount_amount" label="优惠金额">
            <template #default="scope">
                <span v-if="scope.row.discount_amount == 0">&#160;-</span>
                <span v-else>¥&#160;{{ scope.row.discount_amount }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="reduce_amount" label="客服减免" v-if="customer == false">
            <template #default="scope">
              <span v-if="scope.row.reduce_amount == 0">&#160;-</span>
              <span v-else>¥&#160;{{ scope.row.reduce_amount }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="order_amount" label="实付金额">
            <template #default="scope">
              <span>¥&#160;{{scope.row.order_amount}}</span>
            </template>
          </el-table-column>
        </el-table>
        <template #footer>
          <span class="dialog-footer">
            <el-button v-if="this.detailModal.form.status == 0" @click="changeInvoices">取消申请</el-button>
            <el-button type="primary" @click="closeInvoice">关闭</el-button>
          </span>
        </template>
    </el-dialog>
  </div>
</template>

<script>
import { tableConfigBase } from 'utils/common.js'
import { getInvoiceList, changeInvoice, cancelInvoice } from 'api/invoice/list'
import { getinvoiceOrderList } from 'api/invoice/orderList'
import { getOrderList } from 'api/invoice/orderList'
import { GetUserState } from 'api/user/auth/login'
import { showConfirmMessageBox, showMessage } from 'api/base/message'
import ElDescription from 'components/common/el-descriptions/ElDescription'
import ElDescriptionItem from 'components/common/el-descriptions/ElDescriptionItem'
import Pagination from 'components/common/Pagination'
export default {
  components: {
    ElDescription,
    ElDescriptionItem,
    Pagination,
  },
  data() {
    return {
      ticketList: { //表格数据
        config: tableConfigBase(),
        data: [],
        selectedRows: [],
        pager:{},
        searchForm:{},
        form: {
          id: '',
          status: ''
        },
        loading: true,
      },
      customer:false,
      disabledDel: true,
      orderList: {
        config: tableConfigBase(),
        selectedRows: [],
        data:[]
      },
      detailModal: {
        visible: false,
        form: {
          id: '',
          uuid: '',
          owner_id: '',
          type: '0',
          money: '',
          title: '',
          taxpayer_num: '',
          taxpayer_addr: '',
          taxpayer_phone: '',
          account_bank: '',
          account_num: '',
          post_addr: '',
          email_addr: '',
          medium: '0',
          created_at: '',
          status: '0',
          status_instructe: '',
          updated_at: '',
          express_company:'',
          express_num:'',
          addressee:'',
          reci_phone:''
        },
        orderList: {
          data: []
        },
      },
      form:{
        owner_id : ''
      },
      user:{
        user_id: null
      },
    }
  },
  mounted() {
    this.ticketList.pager = {
      pageSize:10,
      currentPage: 1
    }
    this.getOwner()
  },
  methods: {
    getOwner(){
      const that = this;
      GetUserState().then((res) => {
        if (res.id) {
          this.user.user_id = res.id
          var owner_id = this.user.user_id
          this.form.owner_id = owner_id
          that.getData();
        }else {
          this.form.owner_id = 'NO-USER'
          that.getData();
        }
      })
    },
    getData() {
      const that = this
      var data = {'owner_id' : this.form.owner_id}
      data['offset'] = (that.ticketList.pager.currentPage - 1) * that.ticketList.pager.pageSize
      data['limit'] = that.ticketList.pager.pageSize
      getInvoiceList(data).then((res) => {
      console.log(res.data)
        var data = res.data
        var total = res.total
        that.ticketList.data = data
        that.ticketList.pager.total = total
        that.ticketList.loading = false
      })
    },
    showInvoice(row) {
      this.detailModal.visible = true
      this.detailModal.form = {
        id: row.id,
        type :  row.type,
        medium : row.medium,
        money : row.money,
        title : row.title,
        company : row.company,
        taxpayer_num : row.taxpayer_num,
        taxpayer_addr : row.taxpayer_addr,
        taxpayer_phone: row.taxpayer_phone,
        account_bank : row.account_bank,
        account_num : row.account_num,
        post_addr_str : row.position_str,
        email_addr : row.email_addr,
        created_at : row.created_at,
        status : row.status,
        express_company : row.express_company,
        express_num : row.express_num,
        updated_at : row.updated_at,
        status_instructe: row.status_instructe,
        reci_phone: row.reci_phone,
        addressee: row.addressee,
      }
      var orderlist = row.order_uuids
      getOrderList(orderlist).then((res) => {
        this.orderList.data = res.data
        let cus = res.customer
        if (cus === 0){
            this.customer = true
        }
      })
    },
//    修改
    updateInvoice(row) {
      this.goPath({path:"/center/ticket/apply",
                   query:{uuid: row.uuid}
      })
    },
//    取消
    changeInvoices() {
      showConfirmMessageBox('确定要取消这条开票吗？')
        .then(() => {
          const that = this
          var invoice_id = that.detailModal.form.id
          var values = {
            status: '1',
          }
          changeInvoice(invoice_id, values).then(() => {
            this.detailModal.visible = false
            this.getData();
          })
        })
    },
//    取消
    changesInvoice(row) {
      console.log(row.id)
      showConfirmMessageBox('确定要取消开票吗？').then(() => {
        var invoice_id = row.id
        var values = {
            status : '1',
        }
        changeInvoice(invoice_id, values).then(() => {
          this.detailModal.visible = false
          this.getData();
        })
      })
    },
    getinvoicePager(pager) { //获取分页变化后数据
        this.ticketList.pager = pager
        this.getData()
      },
    goPath(path) {
        this.$router.push(path);
      },
    handleCommand(command) {  //批量操作
      if(command === 'change') {
        var rows = [];
        for(var i=0; i < this.ticketList.selectedRows.length; i++){
          rows.push(this.ticketList.selectedRows[i].id)
        }
        this.cancelInvoices(rows.join(','))
      }
    },
    handleSelectionChange(val){
      this.ticketList.selectedRows = val;
      if (this.ticketList.selectedRows.length == 0) {
          this.disabledDel = true
      }
      else {
        this.disabledDel = false
        for(var i = 0; i < this.ticketList.selectedRows.length; i++){
          if(this.ticketList.selectedRows[i].status != 0){
            this.disabledDel = true
            break
          }
        }
      }
    },
    //    批量取消
    cancelInvoices(cancel_id) {
      showConfirmMessageBox('确定要取消选中的开票吗？').then(() => {
        var invoice_id = cancel_id
        cancelInvoice({id:invoice_id}).then(() => {
          this.getData();
        })
      })
    },
    closeInvoice() {
      this.detailModal.visible = false
      this.getData()
      this.customer = false
    },
    close_dialog() {
      this.detailModal.visible = false
      this.getData()
      this.customer = false
    }
  },
}
</script>
