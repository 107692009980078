import { fetch, post, del } from 'api/base/axios'

const URL = {
  invoiceOrderListUrl: '/invoice/get_invoiceOrder_list/',
  getorderListUrl: '/invoice/get_order_list/',

  exportInvoiceUrl: '/invoice/export/',
  queryExportInvoiceUrl: '/invoice/export/query/',

  downloadInvoiceUrl: '/invoice/download/',
}


export function getinvoiceOrderList (invoice_uuid) {
  return post(URL.invoiceOrderListUrl, {
    'invoice_uuid': invoice_uuid
  })
}
export function getOrderList (order_uuid) {
  return post(URL.getorderListUrl, {
    'order_uuid' : order_uuid
  })
}

export function exportInvoice(data){
  return post(URL.exportInvoiceUrl, data)
}

export function queryExportInvoice(){
  return fetch(URL.queryExportInvoiceUrl)
}

export function download_invoice(data){
  return fetch(URL.downloadInvoiceUrl,data)
}
